.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Google Font Poppins */
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');

/* Variables */
:root {
    --header-height: 3rem;

    /* Colors */
    --title-color: #0B0A0A;
    --text-color: #403A3A;
    --text-color-light: #707070;
    --container-color: #FAFAFA;
    --container-color-alt: #F0EFEF;
    --body-color: #FCFCFC;

    /* Typography */
    --body-font: 'Poppins', sans-serif;

    /* Fonts size */
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: .938rem;
    --small-font-size: .875rem;
    --smaller-font-size: .813rem;

    /* Fonts weight */
    --font-medium: 500;
    --font-semi-bold: 600;

    /* Margins */
    --margin-1: .5rem;
    --margin-2: 1rem;
    --margin-3: 1.5rem;

    /* Z Index */
    --z-tooltip: 10;
    --z-fixed: 100;
}